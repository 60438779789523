export const StateChangeDesc = ({ running, start }) => {
  return (
    <>
      {running ?
        <>
          <button className="btn btn-sm btn-outline-danger" disabled>
            <i className="fas fa-stop"></i>&nbsp;Stop
          </button> &nbsp;
          <button className="btn btn-sm btn-outline-dark" disabled>
            <i className="fas fa-skull-crossbones"></i>&nbsp;Kill
          </button>
        </>
        :
        start ?
          <button className="btn btn-sm btn-outline-success" disabled>
            <i className="fas fa-plus"></i>&nbsp;Agregar
          </button>
          :
          <>
            <button className="btn btn-sm btn-outline-success" disabled>
              <i className="fas fa-play"></i>&nbsp;Start
            </button>&nbsp;
            <button className="btn btn-sm btn-outline-success" disabled>
              <i className="fas fa-backward-step"></i>&nbsp;Restart
            </button>&nbsp;
            <button className="btn btn-sm btn-outline-info" disabled>
              <i className="fas fa-rotate"></i>&nbsp;Continue
            </button>&nbsp;
            <button className="btn btn-sm btn-outline-danger" disabled>
              <i className="fas fa-stop"></i>&nbsp;Stop
            </button>&nbsp;
            <button className="btn btn-sm btn-outline-dark" disabled>
              <i className="fas fa-skull-crossbones"></i>&nbsp;Kill
            </button>
          </>
      }
    </>
  )
}
