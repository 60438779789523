import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ConfirmationModal from '../common/ConfirmationModal';
import './Browse.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useConnectionContext } from './ConnectionContext';
import { useLayoutContext } from './LayoutContext';

// Definir la cantidad de elementos por página
const ITEMS_PER_PAGE = 5;

// Función para crear un componente de navegación genérico
const createBrowseComponent = ({
  browse_json_fields,
  form_json_fields,
  sys_texts,
  crud_texts,
  routes,
  get_all_records,
  delete_record,
  update_record
}) => {
  const BrowseComponent = () => {
    // Obtener datos y funciones del contexto de conexión
    const { data, updateData, setSelectedRecord, set_vars } = useConnectionContext();
    const { updateFooterMessage, selectedDominio, selectedWorkspace, updateBgColor } = useLayoutContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(location.state?.currentPage || 1);
    const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || '');
    const [showModal, setShowModal] = useState(false);
    const [showModalState, setShowModalState] = useState(false);
    const [showModalStart, setShowModalStart] = useState(false);
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalPositive, setModalPositive] = useState('');
    const [modalNegative, setModalNegative] = useState('');
    const [stateChangeAction, setstateChangeAction] = useState('');

    // Define las variables del entorno
    const {
      set_browse_selection: isBrowseSelection,
      set_browse_resumen: isBrowseResumen,
      set_browse_running: isBrowseRunning,
      set_browse_upload: isBrowseUpload,
      set_browse_upload_def: isBrowseUploadDef,
      set_browse_start: isBrowseStart,
      set_browse_cruce: isBrowseCruce,
      set_browse_cruce_campos: isBrowseCruceCampos,
      set_browse_not_dom_req: isBrowseNotDomReq,
      set_browse_ope_steps: isBrowseOpeSteps,
    } = set_vars();
    const { destino } = useParams();

    // Cargar todos los registros al montar el componente
    useEffect(() => {
      const fetchRecords = async () => {
        let records = [];
        //OBTIENE TODOS LOS REGISTROS PROVENIENTES DE UNA SELECCIÓN
        if (location.state?.idSelection) {
          records = await get_all_records(location.state.idSelection);
          //OBTIENE TODOS LOS REGISTROS PARA UPLOAD DE DS O PARA INICIO O RESUMEN DE PROCESO
        } else if (isBrowseUpload || isBrowseStart || isBrowseResumen) {
          if (selectedWorkspace) {
            records = await get_all_records({ 'dom_id': selectedDominio, 'wks_id': selectedWorkspace });
          }
          //OBTIENE TODOS LOS REGISTROS PARA STEPS DE OPERACIÓN
        } else if (isBrowseOpeSteps) {
          if (selectedWorkspace && location.state?.processId) {
            records = await get_all_records({ 'process_id': location.state.processId, 'process_dom_id': selectedDominio, 'process_exec_spacework': selectedWorkspace, });
          }
          //OBTIENE TODOS LOS REGISTROS CON DEPENDENCIA DEL DOMINIO
        } else if (!isBrowseNotDomReq) {
          if (selectedDominio) {
            records = await get_all_records({ 'dom_id': selectedDominio });
          }
          //OBTIENE TODOS LOS REGISTROS SIN DEPENDENCIA DEL DOMINIO
        } else {
          records = await get_all_records();
        }

        if (isBrowseUpload) { //INCORPORA WORKSPACE PARA UPLOAD
          records.forEach((item) => {
            item.dse_wks_id = selectedWorkspace;
          });
        }

        if (isBrowseStart) { //INCORPORA WORKSPACE PARA INICIO DE PROCESO
          records.forEach((item) => {
            item.process_wks_id = selectedWorkspace;
          });
        }

        //NO ACTUALIZA DATA SI ES UPLOAD, START O RESUMEN Y NO HAY WORKSPACE SELECCIONADO
        //O SI REQUIERE DOMINIO Y NO HAY DOMINIO SELECCIONADO
        if (
          (!isBrowseUpload || selectedWorkspace) &&
          (!isBrowseStart || selectedWorkspace) &&
          (!isBrowseResumen || selectedWorkspace) &&
          (!isBrowseOpeSteps || selectedWorkspace) &&
          (isBrowseNotDomReq || selectedDominio)
        )
          updateData(records);
      };

      fetchRecords();

    }, [
      updateData,
      location.state?.idSelection,
      isBrowseResumen,
      isBrowseUpload,
      isBrowseStart,
      selectedWorkspace,
      isBrowseNotDomReq,
      selectedDominio,
      isBrowseOpeSteps,
      location.state?.processId
    ]);

    useEffect(() => {
      if (isBrowseResumen) {
        if (selectedWorkspace) {
          if (isBrowseRunning) {
            updateFooterMessage('runningChangeDesc');
            updateBgColor('informational');
          } else {
            updateFooterMessage('stateChangeDesc');
            updateBgColor('informational');
          }
        } else {
          updateFooterMessage('Debe seleccionar WorkSpace para poder iniciar Proceso');
          updateBgColor('alert');
        }
      }
      else if (isBrowseStart) {
        if (selectedWorkspace) {
          updateFooterMessage('startChangeDesc');
          updateBgColor('informational');
        } else {
          updateFooterMessage('Debe seleccionar WorkSpace para poder iniciar Proceso');
          updateBgColor('alert');
        }
      } else if (isBrowseUpload && !selectedWorkspace) {
        updateFooterMessage('Debe seleccionar WorkSpace para poder cargar Data Source');
        updateBgColor('alert');
      } else if (!isBrowseNotDomReq && !selectedDominio) {
        updateFooterMessage('Debe seleccionar Dominio para poder continuar');
        updateBgColor('alert');
      }
      else {
        updateFooterMessage('Zona de mensajes o alertas');
        updateBgColor('bland');
      }

    }, [
      updateBgColor,
      updateFooterMessage,
      isBrowseNotDomReq,
      isBrowseResumen,
      isBrowseRunning,
      isBrowseStart,
      isBrowseUpload,
      selectedDominio,
      selectedWorkspace,
    ]);

    // Manejar la selección de un registro
    const handleSelect = (record) => {
      navigate(routes.select + destino, { state: { idSelection: getId(record) } });
    };

    // Manejar la selección de un proceso en operación
    const handleSteps = (record) => {
      navigate(routes.select, { state: { processId: record.process_id } });
    };

    // Manejar la carga de un archivo
    const handleUpload = (record) => {
      setSelectedRecord(record);
      if (isBrowseUpload) {
        navigate(routes.upload, { state: { set_enable_key_fields: false, currentPage, searchTerm, upload: true, selectedWorkspace: selectedWorkspace } });
      }
      else if (isBrowseUploadDef) {
        navigate(routes.upload, { state: { set_enable_key_fields: false, currentPage, searchTerm, upload: true, uploadDef: true } });
      }
    };

    // Manejar la descarga del resultado de un step
    const handleDownload = (record) => {

    };

    // Manejar la edición de un registro
    const handleEdit = (record) => {
      setSelectedRecord(record);
      location.state?.idSelection ?
        navigate(routes.edit, { state: { set_enable_key_fields: false, currentPage, searchTerm, edit: true, idSelection: location.state.idSelection, isBrowseNotDomReq: isBrowseNotDomReq } })
        :
        navigate(routes.edit, { state: { set_enable_key_fields: false, currentPage, searchTerm, edit: true, cruce: isBrowseCruce, cruceCampos: isBrowseCruceCampos, isBrowseNotDomReq: isBrowseNotDomReq } });
    };

    // Manejar el cambio de estado de un proceso
    const handleStateChange = (id, action) => {
      setShowModalState(true);
      setSelectedRecordId(id);
      setstateChangeAction(action);
      setModalMessage(crud_texts.modal_message);
      setModalTitle(crud_texts.modal_title);
      setModalPositive(crud_texts.modal_positive);
      setModalNegative(crud_texts.modal_negative);
    };

    // Confirmar el cambio de estado de un proceso
    const confirmStateChange = async () => {
      if (selectedRecordId && stateChangeAction && selectedWorkspace) {
        await update_record(selectedRecordId, stateChangeAction);
        setShowModalState(false);
        setSelectedRecordId(null);
        const records = await get_all_records({ 'dom_id': selectedDominio, 'wks_id': selectedWorkspace });
        updateData(records);
      }
    };

    // Manejar el inicio de un proceso
    const handleStartProcess = (record) => {
      setShowModalStart(true);
      setSelectedRecordId(record);
      setModalMessage(crud_texts.modal_message);
      setModalTitle(crud_texts.modal_title);
      setModalPositive(crud_texts.modal_positive);
      setModalNegative(crud_texts.modal_negative);
    };

    // Confirmar el cambio de estado de un proceso
    const confirmStartProcess = async () => {
      if (selectedRecordId && selectedWorkspace) {
        await update_record(selectedRecordId);
        setShowModalStart(false);
        setSelectedRecordId(null);
        const records = await get_all_records({ 'dom_id': selectedDominio, 'wks_id': selectedWorkspace });
        updateData(records);
      }
    };

    // Manejar la creación de un nuevo registro
    const handleNew = () => {
      location.state?.idSelection ?
        navigate(routes.new, { state: { set_enable_key_fields: true, currentPage, searchTerm, edit: false, idSelection: location.state.idSelection, isBrowseNotDomReq: isBrowseNotDomReq } })
        :
        navigate(routes.new, { state: { set_enable_key_fields: true, currentPage, searchTerm, edit: false, cruce: isBrowseCruce, cruceCampos: isBrowseCruceCampos, isBrowseNotDomReq: isBrowseNotDomReq } });
    };

    // Manejar la eliminación de un registro
    const handleDelete = (id) => {
      setShowModal(true);
      setSelectedRecordId(id);
      setModalMessage(crud_texts.modal_message);
      setModalTitle(crud_texts.modal_title);
      setModalPositive(crud_texts.modal_positive);
      setModalNegative(crud_texts.modal_negative);
    };

    // Confirmar la eliminación del registro
    const confirmDelete = async () => {
      if (selectedRecordId) {
        await delete_record(selectedRecordId);
        setShowModal(false);
        setSelectedRecordId(null);
        // const records = await get_all_records(location.state?.idSelection);


        let records = [];
        if (location.state?.idSelection) {
          records = await get_all_records(location.state.idSelection);
          //OBTIENE TODOS LOS REGISTROS PARA UPLOAD DE DS O PARA INICIO O RESUMEN DE PROCESO
        } else if (!isBrowseNotDomReq) {
          if (selectedDominio) {
            records = await get_all_records({ 'dom_id': selectedDominio });
          }
          //OBTIENE TODOS LOS REGISTROS SIN DEPENDENCIA DEL DOMINIO
        } else {
          records = await get_all_records();
        }



        updateData(records);
      }
    };

    // Manejar la búsqueda de registros
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };

    // Manejar la paginación anterior
    const handlePreviousPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    // Manejar la paginación siguiente
    const handleNextPage = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredData.length / ITEMS_PER_PAGE)));
    };

    // Filtrar los datos según el término de búsqueda
    const filteredData = data.filter(
      (item) =>
        browse_json_fields.some(field =>
          item[field.field].toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // Paginar los datos filtrados
    const paginatedData = filteredData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);


    // Obtiene id a partir de todos los campos con atributo key en browse_json_fields
    const getId = (item) => {
      const keyObject = {};
      browse_json_fields.forEach(f => {
        if ('key' in f)
          keyObject[f.field] = item[f.field]
      });
      const key = JSON.stringify(keyObject);
      return key;
    };

    // Determina si aplica botón para edición
    const isEditable = () => {
      if (isBrowseCruce)
        return true;
      const fields = form_json_fields.some(
        (f) => 'key' in f === false
      );
      return fields;
    };

    return (
      <div className="browse_card">
        <div className="browse_card-header">
          <h4 className="browse_title">{crud_texts.browse_titulo}</h4>
          <div className="browse_header-right">
            <div className="browse_search-bar">
              <input
                type="text"
                placeholder={sys_texts.search_placeholder}
                value={searchTerm}
                onChange={handleSearch}
              />
              <button className="btn btn-outline-secondary">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="browse_card-body">
          <table className="browse_table">
            <thead>
              <tr>
                {browse_json_fields.map((field) => (
                  <th key={field.field}>{field.title}</th>
                ))}
                {isBrowseResumen ?
                  <th className="text-right">Steps</th>
                  :
                  null
                }
                <th className="text-right">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={getId(item)} className={getId(item) === selectedRecordId ? 'highlighted' : ''}>
                  {browse_json_fields.map((field) => (
                    <td key={field.field}>{item[field.field]}</td>
                  ))}
                  {isBrowseOpeSteps ?
                    <td className="text-right">
                      <button className="btn btn-sm btn-outline-primary" onClick={() => handleDownload(item)}>
                        <i className="fas fa-download"></i>
                      </button>
                    </td>
                    :
                    null
                  }
                  {isBrowseResumen ?
                    <td className="text-right">
                      <button className="btn btn-sm btn-outline-warning" onClick={() => handleSteps(item)}>
                        <i className="fas fa-list-check"></i>
                      </button>
                    </td>
                    :
                    null
                  }
                  <td className="text-right">
                    {isBrowseSelection ?
                      <button className="btn btn-sm btn-outline-warning" onClick={() => handleSelect(item)}>
                        <i className="fas fa-check"></i>
                      </button>
                      : null
                    }
                    {isBrowseUpload || isBrowseUploadDef ?
                      <button className="btn btn-sm btn-outline-primary" onClick={() => handleUpload(item)}>
                        <i className="fas fa-upload"></i>
                      </button>
                      : null
                    }
                    {isBrowseStart ?
                      <button className="btn btn-sm btn-outline-success" onClick={() => handleStartProcess(item)}>
                        <i className="fas fa-plus"></i>
                      </button>
                      : null
                    }
                    {isBrowseResumen && item.process_exec_status === 'SPS_PENDING' ?
                      <button className="btn btn-sm btn-outline-success" onClick={() => handleStateChange(getId(item), 'restart')}>
                        <i className="fas fa-play"></i>
                      </button>
                      : null
                    }
                    {isBrowseResumen && item.process_exec_status !== 'SPS_PENDING' && item.process_exec_status !== 'SPS_RUNNING' ?
                      <button className="btn btn-sm btn-outline-success" onClick={() => handleStateChange(getId(item), 'restart')}>
                        <i className="fas fa-backward-step"></i>
                      </button>
                      : null
                    }
                    {isBrowseResumen && (item.process_exec_status === 'SPS_STOPPED' || item.process_exec_status === 'SPS_FAILED') ?
                      <button className="btn btn-sm btn-outline-info" onClick={() => handleStateChange(getId(item), 'continue')}>
                        <i className="fas fa-rotate"></i>
                      </button>
                      : null
                    }
                    {isBrowseResumen && (item.process_exec_status === 'SPS_RUNNING') ?
                      <button className="btn btn-sm btn-outline-danger" onClick={() => handleStateChange(getId(item), 'stop')}>
                        <i className="fas fa-stop"></i>
                      </button>
                      : null
                    }
                    {isBrowseResumen && (item.process_exec_status === 'SPS_RUNNING') ?
                      <button className="btn btn-sm btn-outline-dark" onClick={() => handleStateChange(getId(item), 'kill')}>
                        <i className="fas fa-skull-crossbones"></i>
                      </button>
                      : null
                    }
                    {isEditable() && !isBrowseSelection && !isBrowseResumen && !isBrowseUpload && !isBrowseUploadDef ?
                      <button className="btn btn-sm btn-outline-primary" onClick={() => handleEdit(item)}>
                        <i className="fas fa-edit"></i>
                      </button>
                      : null
                    }
                    {!isBrowseSelection && !isBrowseResumen && !isBrowseUpload && !isBrowseUploadDef && !isBrowseStart && !isBrowseCruce && !isBrowseOpeSteps?
                      <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(getId(item))}>
                        <i className="fas fa-trash"></i>
                      </button>
                      : null
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="browse_card-footer">
          <div className="browse_pagination-controls">
            <button className="browse_btn browse_btn-outline-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>
              &laquo;
            </button>
            <span className="browse_page-info">
              {currentPage} / {Math.ceil(filteredData.length / ITEMS_PER_PAGE)}
            </span>
            <button className="browse_btn browse_btn-outline-primary" onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredData.length / ITEMS_PER_PAGE)}>
              &raquo;
            </button>
          </div>
          {isBrowseSelection || isBrowseResumen || isBrowseUpload || isBrowseUploadDef || isBrowseStart || isBrowseCruce || isBrowseOpeSteps ?
            null
            : <button className="browse_btn browse_btn-new" onClick={handleNew}>
              {sys_texts.browse_nuevo}
            </button>
          }
        </div>
        <ConfirmationModal show={showModal} onHide={() => setShowModal(false)} onConfirm={confirmDelete} message={modalMessage} title={modalTitle} positive={modalPositive} negative={modalNegative} />
        <ConfirmationModal show={showModalState} onHide={() => setShowModalState(false)} onConfirm={confirmStateChange} message={modalMessage} title={modalTitle} positive={modalPositive} negative={modalNegative} />
        <ConfirmationModal show={showModalStart} onHide={() => setShowModalStart(false)} onConfirm={confirmStartProcess} message={modalMessage} title={modalTitle} positive={modalPositive} negative={modalNegative} />
      </div>
    );
  };

  return BrowseComponent;
};

export default createBrowseComponent;