import { get_all_records, delete_record, update_record, insert_record } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Steps Proceso",
  form_titulo: "Step Proceso",
  modal_message: "¿Está seguro que desea eliminar este step?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'step_id', title: 'ID de Step', key: true, type: 'text', len: 20 },
  { field: 'step_rule_id', title: 'ID de Regla', type: 'text', len: 50 },
  { field: 'step_exec_order', title: 'Orden de Step', type: 'number', len: 11 },
  { field: 'step_exec_name', title: 'Nombre de Step', type: 'text', len: 255 },
  { field: 'step_exec_status', title: 'Estado de Step', type: 'text', len: 20 },
  { field: 'step_exec_progress', title: 'Estado de Step', type: 'number', len: 11 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_ope_steps: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record };