import axios from 'axios';

const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_upload = 'https://www.reclamospae.cl:5000/api/files/upload';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    let objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_id", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_name\\\\": \\\\"\\\\", \\\\"dse_template_name\\\\": \\\\"\\\\", \\\\"dse_desc\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    let jsonBody = JSON.stringify(objBody);
    let myUrl = url_api_GWA;
    let response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataElementId = await response.json();

    let result = [];
    let dataFormat = [];

    for (let i = 0; i < dataElementId.length; i++) {
      objBody = {};
      objBody.sql_id = 'GWA_CRUD';
      objBody.params = '[]';
      objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "ds_element_fmt", "GC_JSF": "{\\\\"dse_dom_id\\\\": \\\\"\\\\", \\\\"dse_id\\\\": \\\\"\\\\", \\\\"dse_rectype_id\\\\": \\\\"\\\\", \\\\"dse_field_number\\\\": \\\\"\\\\", \\\\"dse_field_name\\\\": \\\\"\\\\", \\\\"dse_field_type\\\\": \\\\"\\\\", \\\\"dse_field_len\\\\": \\\\"\\\\", \\\\"dse_field_dec\\\\": \\\\"\\\\", \\\\"dse_field_pic\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dse_dom_id\\\\": \\\\"' + dataElementId[i].dse_dom_id + '\\\\", \\\\"dse_id\\\\": \\\\"' + dataElementId[i].dse_id + '\\\\"}"}';
      jsonBody = JSON.stringify(objBody);
      myUrl = url_api_GWA;
      response = await fetch(myUrl, {
        headers: {
          "Content-Type": "application/JSON"
        },
        method: "POST",
        body: jsonBody
      });
      if (response.ok) {
        dataFormat = await response.json();
        if (dataFormat.length > 0) {
          let currentLength = 0;
          let maxLength = 0;
          let prevRectype = '';
          for (let j = 0; j < dataFormat.length; j++) {
            if (dataFormat[j].dse_rectype_id !== prevRectype && prevRectype !== '' && currentLength > maxLength) {
              maxLength = currentLength;
              currentLength = 0;
            }
            prevRectype = dataFormat[j].dse_rectype_id;
            currentLength += dataFormat[j].dse_field_len;
          }
          if (currentLength > maxLength) {
            maxLength = currentLength;
          }
          result = [...result, { ...dataElementId[i], dse_max_len: maxLength }];
        }
      }
    }
    return result;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (data) => {
  return null;
};

// Ingresar un registro
const insert_record = async (formData, updateUploading, updateProgress) => {
  updateProgress(0);
  updateUploading(true);
  try {
    await axios.post(url_api_upload, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateProgress(percentCompleted);
      },
    });
    updateProgress(100);
    return null;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };