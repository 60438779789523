import { get_all_records, delete_record, update_record, insert_record } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: " - Seleccione Proceso",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'process_id', title: 'ID de Proceso', key: true, type: 'text', len: 20 },
  { field: 'process_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 20 },
  { field: 'process_name', title: 'Nombre', type: 'text', len: 255 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  select: '/configuration/procesos/'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_selection: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record };