import { CFooter } from '@coreui/react';
import { useLayoutContext } from '../../mbr_crud/LayoutContext';
import { StateChangeDesc } from './footer_components/StateChangeDesc';

const Footer = () => {
  const colors = {
    informational: '#f0e4aa',
    alert: '#ffcd39',
    bland: '#f5f5f5',
  };

  const { footerMessage, bgColor } = useLayoutContext();
  return (
    <CFooter position="fixed" className="text-dark font-weight-bold" style={{ backgroundColor: `${colors[bgColor] ? colors[bgColor] : colors.bland}` }}>
      <div></div>
      <div className="text-center">
        {footerMessage === 'stateChangeDesc' ?
          <StateChangeDesc />
          :
          footerMessage === 'runningChangeDesc' ?
            <StateChangeDesc running="true"/>
            :
            footerMessage === 'startChangeDesc' ?
            <StateChangeDesc start="true"/>
            :
          <p className="font-weight-bold">{footerMessage}</p>
        }
      </div>
      <div></div>
    </CFooter>
  );
};

export default Footer;