import axios from 'axios';

const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_upload_def = 'https://www.reclamospae.cl:5000/api/files/uploadFMT';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_dominio", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"\\\\", \\\\"dom_name\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dom_id\\\\": \\\\"' + dom_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (data) => {
  return null;
};

// Ingresar un registro
const insert_record = async (formData, updateUploading, updateProgress) => {
  updateProgress(0);
  updateUploading(true);
  try {
    await axios.post(url_api_upload_def, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateProgress(percentCompleted);
      },
    });
    updateProgress(100);
    return null;
  } catch (error) {
    console.error('Error uploading file:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };