const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { process_id, process_dom_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"step_id\\\\": \\\\"\\\\", \\\\"step_rule_id\\\\": \\\\"\\\\", \\\\"step_order\\\\": \\\\"\\\\", \\\\"step_name\\\\": \\\\"\\\\", \\\\"step_type\\\\": \\\\"\\\\", \\\\"step_cmd\\\\": \\\\"\\\\", \\\\"step_common_argv\\\\": \\\\"\\\\", \\\\"step_additional_argv\\\\": \\\\"\\\\", \\\\"step_text\\\\": \\\\"\\\\", \\\\"step_active\\\\": \\\\"\\\\", \\\\"step_run_mode\\\\": \\\\"\\\\", \\\\"step_alert_at_finish\\\\": \\\\"\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"\\\\", \\\\"step_progress_number_report\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { process_id, process_dom_id, step_id } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    // const { process_id, process_dom_id, step_id, step_rule_id. step_order, step_name, step_type, step_cmd, step_common_argv, step_additional_argv, step_text, step_active, step_run_mode, step_alert_at_finish, step_stop_limit_faults, step_progress_number_report } = data;
    const { process_id, process_dom_id, step_id, step_rule_id, step_order, step_name, step_type, step_cmd, step_text, step_active, step_run_mode, step_alert_at_finish, step_stop_limit_faults, step_progress_number_report } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"step_rule_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"step_order\\\\": \\\\"' + step_order + '\\\\", \\\\"step_name\\\\": \\\\"' + step_name + '\\\\", \\\\"step_type\\\\": \\\\"' + step_type + '\\\\", \\\\"step_cmd\\\\": \\\\"' + step_cmd + '\\\\", \\\\"step_text\\\\": \\\\"' + step_text + '\\\\", \\\\"step_active\\\\": \\\\"' + step_active + '\\\\", \\\\"step_run_mode\\\\": \\\\"' + step_run_mode + '\\\\", \\\\"step_alert_at_finish\\\\": \\\\"' + step_alert_at_finish + '\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"' + step_stop_limit_faults + '\\\\", \\\\"step_progress_number_report\\\\": \\\\"' + step_progress_number_report + '\\\\"}", "GC_JSK": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    const { process_id, process_dom_id, step_id, step_rule_id, step_order, step_name, step_type, step_cmd, step_text, step_active, step_run_mode, step_alert_at_finish, step_stop_limit_faults, step_progress_number_report } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "mbr_step_definition", "GC_JSF": "{\\\\"process_id\\\\": \\\\"' + process_id + '\\\\", \\\\"process_dom_id\\\\": \\\\"' + process_dom_id + '\\\\", \\\\"step_id\\\\": \\\\"' + step_id + '\\\\", \\\\"step_rule_id\\\\": \\\\"' + step_rule_id + '\\\\", \\\\"step_order\\\\": \\\\"' + step_order + '\\\\", \\\\"step_name\\\\": \\\\"' + step_name + '\\\\", \\\\"step_type\\\\": \\\\"' + step_type + '\\\\", \\\\"step_cmd\\\\": \\\\"' + step_cmd + '\\\\", \\\\"step_text\\\\": \\\\"' + step_text + '\\\\", \\\\"step_active\\\\": \\\\"' + step_active + '\\\\", \\\\"step_run_mode\\\\": \\\\"' + step_run_mode + '\\\\", \\\\"step_alert_at_finish\\\\": \\\\"' + step_alert_at_finish + '\\\\", \\\\"step_stop_limit_faults\\\\": \\\\"' + step_stop_limit_faults + '\\\\", \\\\"step_progress_number_report\\\\": \\\\"' + step_progress_number_report + '\\\\"}", "GC_JSK": "{}"}';
    console.log(objBody.sql_ve);
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };