import { get_all_records, delete_record, update_record, insert_record } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "Reglas",
  form_titulo: "Regla",
  modal_message: "¿Está seguro que desea eliminar esta regla?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'rule_routine_id', title: 'ID', key: true, type: 'text', len: 50 },
  { field: 'rule_routine_name', title: 'Nombre', type: 'text', len: 50 },
  { field: 'rule_routine_nemo', title: 'Nemotécnico', type: 'text', len: 20 },
  { field: 'rule_routine_type', title: 'Tipo', type: 'text', len: 20 },
  { field: 'rule_routine_call', title: 'Call', type: 'text', len: 50 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'rule_routine_id', title: 'ID', key: true, type: 'text', len: 50 },
  { field: 'rule_routine_name', title: 'Nombre', type: 'text', len: 50 },
  { field: 'rule_routine_nemo', title: 'Nemotécnico', type: 'text', len: 20 },
  { field: 'rule_routine_type', title: 'Tipo', type: 'text', len: 20 },
  { field: 'rule_routine_call', title: 'Call', type: 'text', len: 50 },
  { field: 'rule_routine_common_argv', title: 'Argumentos Comunes', type: 'text', len: 1000 },
  { field: 'rule_routine_additional_argv', title: 'Argumentos Adicionales', type: 'text', len: 1000 },
  { field: 'rule_routine_file_nok', title: 'No OK', type: 'text', len: 255 },
  { field: 'rule_routine_file_max_nok', title: 'Máximo No OK', type: 'number', len: 11 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit:'/configuration/reglas/edit',
  new: '/configuration/reglas/new',
  list: '/configuration/reglas'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {
    set_browse_not_dom_req: true,
  };
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record };