import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import createBrowseComponent from '../../../mbr_crud/Browse';
import { ConnectionProvider } from '../../../mbr_crud/ConnectionContext';
import {
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
    update_record,
    insert_record,
    set_vars
} from './crud_define';

const BrowseFuentesDSSelection = createBrowseComponent({
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record
});

const CrudRoutesConfFuentesDSSelection = () => {
    const location = useLocation();
    const locationPath = location.pathname.slice(location.pathname.lastIndexOf("/") + 1 , location.pathname.length)
    if (locationPath === 'formato')
        crud_texts.browse_titulo = 'Formato  - Seleccione Data Source';
    if (locationPath === 'registro')
        crud_texts.browse_titulo = 'Tipo de Registro  - Seleccione Data Source';
    return(
    <ConnectionProvider get_all_records={get_all_records}
        delete_record={delete_record}
        update_record={update_record}
        insert_record={insert_record}
        set_vars={set_vars}
    >
        <Routes>
            <Route path="/:destino" element={<BrowseFuentesDSSelection />} />
        </Routes>
    </ConnectionProvider>)
};

export default CrudRoutesConfFuentesDSSelection;