import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import Sidebar from './components/layout/desk/Sidebar';
import Header from './components/layout/desk/Header';
import Footer from './components/layout/desk/Footer';
import CrudRoutesConfDominio from './components/dominios/configuration/crud_routes';
import CrudRoutesConfWorkspace from './components/workspace/configuration/crud_routes';
import CrudRoutesConfReglas from './components/reglas/configuration/crud_routes';
import CrudRoutesConfCrucesId from './components/cruces/configuration/id/crud_routes';
import CrudRoutesConfCrucesKeys from './components/cruces/configuration/keys/crud_routes';
import CrudRoutesConfCrucesCampos from './components/cruces/configuration/campos/crud_routes';
import CrudRoutesConfFuentesDSID from './components/fuentesDS/configuration/id/crud_routes';
import CrudRoutesConfFuentesDSSelection from './components/fuentesDS/configuration/selection/crud_routes';
import CrudRoutesConfFuentesDSFormato from './components/fuentesDS/configuration/formato/crud_routes';
import CrudRoutesConfFuentesDSRegistro from './components/fuentesDS/configuration/registro/crud_routes';
import CrudRoutesConfFuentesDSUpload from './components/fuentesDS/configuration/upload/crud_routes';
import CrudRoutesConfProcesosProcesos from './components/procesos/configuration/procesos/crud_routes';
import CrudRoutesConfProcesosSelection from './components/procesos/configuration/selection/crud_routes';
import CrudRoutesConfProcesosSteps from './components/procesos/configuration/steps/crud_routes';
import CrudRoutesOpeWorkspace from './components/workspace/operation/crud_routes';
import CrudRoutesOpeFuentesDS from './components/fuentesDS/operation/upload/crud_routes';
import CrudRoutesOpeProcesosStart from './components/procesos/operation/start/crud_routes';
import CrudRoutesOpeProcesosRunning from './components/procesos/operation/running/crud_routes';
import CrudRoutesOpeProcesosResumen from './components/procesos/operation/resumen/crud_routes';
import CrudRoutesOpeProcesosSteps from './components/procesos/operation/steps/crud_routes';
import { LayoutProvider } from './components/mbr_crud/LayoutContext';

const App = () => {
  return (
    <Router>
    <LayoutProvider>
      <div className="app d-flex flex-column h-100">
        <Header />
        <br />
        <br />
        <div className="app-body d-flex flex-grow-1">
          <Sidebar />
          <main className="main flex-grow-1 p-3">
            <CContainer fluid>
              <Routes>
                <Route path="/" element={<Navigate to="/configuration/dominios" />} />
                <Route path="/configuration/dominios/*" element={<CrudRoutesConfDominio />} />
                <Route path="/configuration/workspaces/*" element={<CrudRoutesConfWorkspace />} />
                <Route path="/configuration/reglas/*" element={<CrudRoutesConfReglas />} />
                <Route path="/configuration/cruces/id/*" element={<CrudRoutesConfCrucesId />} />
                <Route path="/configuration/cruces/keys/*" element={<CrudRoutesConfCrucesKeys />} />
                <Route path="/configuration/cruces/campos/*" element={<CrudRoutesConfCrucesCampos />} />
                <Route path="/configuration/fuentesDS/id/*" element={<CrudRoutesConfFuentesDSID />} />
                <Route path="/configuration/fuentesDS/selection/*" element={<CrudRoutesConfFuentesDSSelection />} />
                <Route path="/configuration/fuentesDS/formato/*" element={<CrudRoutesConfFuentesDSFormato />} />
                <Route path="/configuration/fuentesDS/registro/*" element={<CrudRoutesConfFuentesDSRegistro />} />
                <Route path="/configuration/fuentesDS/upload/*" element={<CrudRoutesConfFuentesDSUpload />} />
                <Route path="/configuration/procesos/procesos/*" element={<CrudRoutesConfProcesosProcesos />} />
                <Route path="/configuration/procesos/selection/*" element={<CrudRoutesConfProcesosSelection />} />
                <Route path="/configuration/procesos/steps/*" element={<CrudRoutesConfProcesosSteps />} />
                <Route path="/operation/workspaces/*" element={<CrudRoutesOpeWorkspace />} />
                <Route path="/operation/fuentesDS/upload/*" element={<CrudRoutesOpeFuentesDS />} />
                <Route path="/operation/procesos/start/*" element={<CrudRoutesOpeProcesosStart />} />
                <Route path="/operation/procesos/running/*" element={<CrudRoutesOpeProcesosRunning />} />
                <Route path="/operation/procesos/resumen/*" element={<CrudRoutesOpeProcesosResumen />} />
                <Route path="/operation/procesos/steps/*" element={<CrudRoutesOpeProcesosSteps />} />
              </Routes>
            </CContainer>
          </main>
        </div>
        <br />
        <br />
        <Footer />
      </div>
      </LayoutProvider>
    </Router>
  );
};

export default App;