import React from 'react';
import { Routes, Route } from 'react-router-dom';
import createBrowseComponent from '../../../mbr_crud/Browse';
import createFormComponent from '../../../mbr_crud/Form';
import { ConnectionProvider } from '../../../mbr_crud/ConnectionContext';
import {
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
    update_record,
    insert_record,
    set_vars
} from './crud_define';

const BrowseProcesosResumen = createBrowseComponent({
    browse_json_fields,
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    delete_record,
    update_record
});

const FormProcesosResumen = createFormComponent({
    form_json_fields,
    sys_texts,
    crud_texts,
    routes,
    get_all_records,
    update_record,
    insert_record
});

const CrudRoutesOpeProcesosResumen = () => (
    <ConnectionProvider get_all_records={get_all_records}
        delete_record={delete_record}
        update_record={update_record}
        insert_record={insert_record}
        set_vars={set_vars}
    >
        <Routes>
            <Route path="/" element={<BrowseProcesosResumen />} />
            <Route path="/state" element={<FormProcesosResumen />} />
        </Routes>
    </ConnectionProvider>
);

export default CrudRoutesOpeProcesosResumen;