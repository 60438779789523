const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los dominios
const get_all_dominios = async () => {
    try {
        const objBody = {};
        objBody.sql_id = 'GWA_CRUD';
        objBody.params = '[]';
        objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_dominio", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"\\\\", \\\\"dom_name\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
        const jsonBody = JSON.stringify(objBody);
        const myUrl = url_api_GWA;
        const response = await fetch(myUrl, {
            headers: {
                "Content-Type": "application/JSON"
            },
            method: "POST",
            body: jsonBody
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch data failed:', error);
        return [];
    }
};

// Obtener todos los workspaces asociados a un dominio
const get_all_workspaces = async (dominio) => {
    try {
        const objBody = {};
        objBody.sql_id = 'GWA_CRUD';
        objBody.params = '[]';
        objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_wks", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"\\\\", \\\\"wks_id\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"dom_id\\\\": \\\\"' + dominio + '\\\\"}"}';
        const jsonBody = JSON.stringify(objBody);
        const myUrl = url_api_GWA;
        const response = await fetch(myUrl, {
            headers: {
                "Content-Type": "application/JSON"
            },
            method: "POST",
            body: jsonBody
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch data failed:', error);
        return [];
    }
};

export { get_all_dominios, get_all_workspaces };