import React, { createContext, useContext, useState } from 'react';

// Crear un contexto para los registros
const LayoutContext = createContext();

export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [footerMessage, setFooterMessage] = useState('');
  const [selectedDominio, setSelectedDominio] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedMenu, setSelectedMenu] = useState('');
  const [bgColor, setBgColor] = useState('');

  const updateFooterMessage = (message) => setFooterMessage(message);
  const updateSelectedDominio = (dominio) => setSelectedDominio(dominio);
  const updateSelectedWorkspace = (workspace) => setSelectedWorkspace(workspace);
  const updateSelectedMenu = (menu) => setSelectedMenu(menu);
  const updateBgColor = (bgColor) => setBgColor(bgColor);

  return (
    <LayoutContext.Provider value={{
      footerMessage,
      updateFooterMessage,
      selectedDominio,
      updateSelectedDominio,
      selectedWorkspace,
      updateSelectedWorkspace,
      selectedMenu,
      updateSelectedMenu,
      bgColor,
      updateBgColor,
    }}>
      {children}
    </LayoutContext.Provider>
  );
};