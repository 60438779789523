const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';

// Obtener todos los registros
const get_all_records = async () => {
  try {
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE_ALL", "GC_TABLE": "mbr_wks_ds", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"\\\\", \\\\"wks_id\\\\": \\\\"\\\\", \\\\"wks_element\\\\": \\\\"\\\\", \\\\"wks_date\\\\": \\\\"\\\\", \\\\"wks_sys_status\\\\": \\\\"\\\\"}", "GC_JSK": "{}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  try {
    const { dom_id, wks_id, wks_element } = JSON.parse(id);
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "DELETE", "GC_TABLE": "mbr_wks_ds", "GC_JSF": "{}", "GC_JSK": "{\\\\"dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"wks_id\\\\": \\\\"' + wks_id + '\\\\", \\\\"wks_element\\\\": \\\\"' + wks_element + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error eliminando el registro');
    }
    return id;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Actualizar un registro
const update_record = async (data) => {
  try {
    const { dom_id, wks_id, wks_element, wks_date, wks_sys_status } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "UPDATE", "GC_TABLE": "mbr_wks_ds", "GC_JSF": "{\\\\"wks_date\\\\": \\\\"' + wks_date + '\\\\", \\\\"wks_sys_status\\\\": \\\\"' + wks_sys_status + '\\\\"}", "GC_JSK": "{\\\\"dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"wks_id\\\\": \\\\"' + wks_id + '\\\\", \\\\"wks_element\\\\": \\\\"' + wks_element + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  try {
    const { dom_id, wks_id, wks_element, wks_date, wks_sys_status } = data;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "INSERT", "GC_TABLE": "mbr_wks_ds", "GC_JSF": "{\\\\"dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"wks_id\\\\": \\\\"' + wks_id + '\\\\", \\\\"wks_element\\\\": \\\\"' + wks_element + '\\\\", \\\\"wks_date\\\\": \\\\"' + wks_date + '\\\\", \\\\"wks_sys_status\\\\": \\\\"' + wks_sys_status + '\\\\"}", "GC_JSK": "{}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Error actualizando el registro');
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export { get_all_records, delete_record, update_record, insert_record };