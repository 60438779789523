import React from 'react';
import { CSidebar, CSidebarNav, CNavItem, CNavLink, CNavTitle, CNavGroup } from '@coreui/react';
import { Link } from 'react-router-dom';
import { useLayoutContext } from '../../mbr_crud/LayoutContext';

const Sidebar = () => {

  const { updateSelectedMenu } = useLayoutContext();

  const onClickMenuOption = (route) => {
    updateSelectedMenu(route);
  }

  return (
    <CSidebar className="bg-white">
      <CSidebarNav>
        <CNavTitle>Configuración</CNavTitle>
        <CNavItem>
          <CNavLink as={Link} to="/configuration/dominios" onClick={() => onClickMenuOption("/configuration/dominios")}>
            Dominios
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink as={Link} to="/configuration/workspaces" onClick={() => onClickMenuOption("/configuration/workspaces")}>
            WorksSpaces
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink as={Link} to="/configuration/reglas" onClick={() => onClickMenuOption("/configuration/reglas")}>
            Reglas
          </CNavLink>
        </CNavItem>
        <CNavGroup toggler={<>Cruces</>} >
          <CNavItem>
            <CNavLink as={Link} to="/configuration/cruces/id" onClick={() => onClickMenuOption("/configuration/cruces/id")}>
              ID
            </CNavLink>
          </CNavItem>
          <CNavItem>
            {/* <CNavLink as={Link} to="/configuration/cruces/selection/keys" onClick={() => onClickMenuOption("/configuration/cruces/selection/keys")}> */}
            <CNavLink as={Link} to="/configuration/cruces/keys" onClick={() => onClickMenuOption("/configuration/cruces/keys")}>
              Keys
            </CNavLink>
          </CNavItem>
          <CNavItem>
            {/* <CNavLink as={Link} to="/configuration/cruces/selection/campos" onClick={() => onClickMenuOption("/configuration/cruces/selection/campos")}> */}
            <CNavLink as={Link} to="/configuration/cruces/campos" onClick={() => onClickMenuOption("/configuration/cruces/campos")}>
              Campos
            </CNavLink>
          </CNavItem>
        </CNavGroup>
        <CNavGroup toggler={<>Data Sources</>} >
          <CNavItem>
            <CNavLink as={Link} to="/configuration/fuentesDS/id" onClick={() => onClickMenuOption("/configuration/fuentesDS/id")}>
              ID
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/configuration/fuentesDS/selection/formato" onClick={() => onClickMenuOption("/configuration/fuentesDS/selection/formato")}>
              Formato
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/configuration/fuentesDS/selection/registro" onClick={() => onClickMenuOption("/configuration/fuentesDS/selection/registro")}>
              Tipo Registro
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/configuration/fuentesDS/upload" onClick={() => onClickMenuOption("/configuration/fuentesDS/upload")}>
              Cargar Definición
            </CNavLink>
          </CNavItem>
        </CNavGroup>
        <CNavGroup toggler={<>Procesos</>} >
          <CNavItem>
            <CNavLink as={Link} to="/configuration/procesos/procesos" onClick={() => onClickMenuOption("/configuration/procesos/procesos")}>
              Procesos
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/configuration/procesos/selection/steps" onClick={() => onClickMenuOption("/configuration/procesos/selection/steps")}>
              Steps
            </CNavLink>
          </CNavItem>
        </CNavGroup>
        <CNavTitle>Operación</CNavTitle>
        {/* <CNavGroup toggler={<>Dominios</>} >
          <CNavItem>
            <CNavLink as={Link} to="/">
              Usar
            </CNavLink>
          </CNavItem>
        </CNavGroup> */}
        <CNavGroup toggler={<>WorksSpaces</>} >
          <CNavItem>
            <CNavLink as={Link} to="/operation/workspaces" onClick={() => onClickMenuOption("/operation/workspaces")}>
              Admin
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink as={Link} to="/">
              Usar
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/">
              Estadísticas
            </CNavLink>
          </CNavItem> */}
        </CNavGroup>
        <CNavGroup toggler={<>Data Sources</>} >
          <CNavItem>
            <CNavLink as={Link} to="/operation/fuentesDS/upload" onClick={() => onClickMenuOption("/operation/fuentesDS/upload")}>
              Cargar Data Source
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink as={Link} to="/">
              Admin DS
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/">
              Admin DB
            </CNavLink>
          </CNavItem> */}
        </CNavGroup>
        <CNavGroup toggler={<>Procesos</>} >
          <CNavItem>
            <CNavLink as={Link} to="/operation/procesos/start" onClick={() => onClickMenuOption("/operation/procesos/start")}>
              Agregar Ejecución
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/operation/procesos/running" onClick={() => onClickMenuOption("/operation/procesos/running")}>
              En Ejecución
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink as={Link} to="/operation/procesos/resumen" onClick={() => onClickMenuOption("/operation/procesos/resumen")}>
              Resumen
            </CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink as={Link} to="/">
              Alertas
            </CNavLink>
          </CNavItem> */}
        </CNavGroup>
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;