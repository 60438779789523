const url_api_GWA = 'https://www.reclamospae.cl:5000/api/GWA';
const url_api_process_exec_start = 'https://www.reclamospae.cl:5000/process_exec/start';
const url_api_process_exec_restart = 'https://www.reclamospae.cl:5000/process_exec/restart';
const url_api_process_exec_continue = 'https://www.reclamospae.cl:5000/process_exec/continue';
const url_api_process_exec_stop = 'https://www.reclamospae.cl:5000/process_exec/stop';
const url_api_process_exec_kill = 'https://www.reclamospae.cl:5000/process_exec/kill';

// Obtener todos los registros
const get_all_records = async (id) => {
  try {
    const { dom_id, wks_id } = id;
    const objBody = {};
    objBody.sql_id = 'GWA_CRUD';
    objBody.params = '[]';
    objBody.sql_ve = '{"GC_CMD": "RETRIEVE", "GC_TABLE": "mbr_process_exec", "GC_JSF": "{\\\\"process_exec_serial\\\\": \\\\"\\\\", \\\\"process_id\\\\": \\\\"\\\\", \\\\"process_dom_id\\\\": \\\\"\\\\", \\\\"process_exec_spacework\\\\": \\\\"\\\\", \\\\"process_exec_name\\\\": \\\\"\\\\", \\\\"process_exec_active\\\\": \\\\"\\\\", \\\\"process_exec_status\\\\": \\\\"\\\\", \\\\"process_exec_init_datetime\\\\": \\\\"\\\\", \\\\"process_exec_finish_datetime\\\\": \\\\"\\\\", \\\\"process_exec_alert_at_finish\\\\": \\\\"\\\\", \\\\"process_exec_stop_number_faults\\\\": \\\\"\\\\", \\\\"process_exec_progress\\\\": \\\\"\\\\"}", "GC_JSK": "{\\\\"process_dom_id\\\\": \\\\"' + dom_id + '\\\\", \\\\"process_exec_spacework\\\\": \\\\"' + wks_id + '\\\\"}"}';
    const jsonBody = JSON.stringify(objBody);
    const myUrl = url_api_GWA;
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch data failed:', error);
    return [];
  }
};

// Eliminar un registro
const delete_record = async (id) => {
  return null;
};

// Actualizar un registro
const update_record = async (id, action) => {
  try {
    const { process_id, process_dom_id, process_exec_spacework } = JSON.parse(id);
    const objBody = {};
    objBody.process_id = process_id;
    objBody.process_dom_id = process_dom_id;
    objBody.process_exec_spacework = process_exec_spacework;
    const jsonBody = JSON.stringify(objBody);
    let myUrl = '';
    switch (action) {
      case 'start':
        myUrl = url_api_process_exec_start;
        break;
      case 'restart':
        myUrl = url_api_process_exec_restart;
        break;
      case 'continue':
        myUrl = url_api_process_exec_continue;
        break;
      case 'stop':
        myUrl = url_api_process_exec_stop;
        break;
      case 'kill':
        myUrl = url_api_process_exec_kill;
        break;
      default:
        myUrl = '';
    }
    const response = await fetch(myUrl, {
      headers: {
        "Content-Type": "application/JSON"
      },
      method: "POST",
      body: jsonBody
    });
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    }
    const updatedData = await response.json();
    return updatedData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Ingresar un registro
const insert_record = async (data) => {
  return null;
};

export { get_all_records, delete_record, update_record, insert_record };