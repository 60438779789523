import React, { useEffect, useState } from 'react';
import { CNavbar, CNavbarBrand, CNavbarNav, CNavItem, CNavLink, CContainer, CForm, CDropdownMenu, CDropdownDivider, CDropdownItem, CDropdown, CDropdownToggle, CCollapse } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { get_all_dominios, get_all_workspaces } from './header_connect';
import { useLayoutContext } from '../../mbr_crud/LayoutContext';

const Header = () => {
  const [searchTermDominio, setSearchTermDominio] = useState('');
  const [searchTermWorkspace, setSearchTermWorkspace] = useState('');
  const {
    selectedDominio,
    updateSelectedDominio,
    selectedWorkspace,
    updateSelectedWorkspace,
    selectedMenu,
  } = useLayoutContext();
  const [dominios, setDominios] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const updateDominios = (newDominios) => setDominios(newDominios);
  const updateWorkspaces = (newWorkspaces) => setWorkspaces(newWorkspaces);
  const navigate = useNavigate();

  const onDropdownDominiosClick = () => {
    const fetchRecords = async () => {
      const dominios = await get_all_dominios();
      updateDominios(dominios);
    };

    fetchRecords();
  };

  const onDropdownWorkspacesClick = () => {
    const fetchRecords = async () => {
      const workspaces = await get_all_workspaces(selectedDominio);
      updateWorkspaces(workspaces);
    };

    fetchRecords();
  };

  useEffect(() => {
    const fetchRecords = async () => {
      const dominios = await get_all_dominios();
      updateDominios(dominios);

      const workspaces = await get_all_workspaces(selectedDominio);
      updateWorkspaces(workspaces);
    };

    fetchRecords();
  }, [selectedDominio]);

  const handleSearchDominio = (e) => {
    setSearchTermDominio(e.target.value);
  };

  const handleSearchWorkspace = (e) => {
    setSearchTermWorkspace(e.target.value);
  };

  const filteredDominios = dominios.filter(
    (item) =>
      item.dom_id.toString().toLowerCase().includes(searchTermDominio.toLowerCase())
  );

  const filteredWorkspaces = workspaces.filter(
    (item) =>
      item.wks_id.toString().toLowerCase().includes(searchTermWorkspace.toLowerCase())
  );

  const onSelectDominio = (e, item) => {
    e.preventDefault();
    if (selectedDominio !== item.dom_id) {
      if (selectedDominio !== '' && selectedMenu !== '') {
        updateSelectedDominio(item.dom_id);
        updateSelectedWorkspace('');
        navigate(selectedMenu);
      } else {
        updateSelectedDominio(item.dom_id);
        updateSelectedWorkspace('');
      }
    }
    const toggle = document.getElementById('dropdownDominio').getElementsByClassName('dropdown-toggle')[0];
    toggle.click();
  };

  const onSelectWorkspace = (e, item) => {
    e.preventDefault();
    updateSelectedWorkspace(item.wks_id);
    const toggle = document.getElementById('dropdownWorkspace').getElementsByClassName('dropdown-toggle')[0];
    toggle.click();
  };

  return (
    <CNavbar colorScheme="light" className="bg-primary" expand="lg" placement="fixed-top">
      <CContainer fluid>
        <CNavbarBrand className="text-white">MBRApp</CNavbarBrand>
        <CCollapse className="navbar-collapse">
          <CNavbarNav>
            <CNavItem>
              <CNavLink className="text-white">Usuario: admin</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink className="text-white">Rol: Administrador</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink className="text-white">Institución: MiInstitucion</CNavLink>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
        <CNavbarNav>
          <CDropdown
            onClick={onDropdownDominiosClick}
            id="dropdownDominio"
            variant="nav-item"
            direction="center"
            autoClose="outside"
          >
            <CDropdownToggle className="text-white">
              {selectedDominio ?
                'Dominio: ' + selectedDominio
                :
                'Seleccione Dominio'}
            </CDropdownToggle>
            <CDropdownMenu className="dropdown-menu-end">
              <CForm className="px-4 py-4">
                <div className="browse_search-bar">
                  <input
                    type="text"
                    value={searchTermDominio}
                    onChange={handleSearchDominio}
                    placeholder="Buscar Dominio"
                  />
                </div>
              </CForm>
              <CDropdownDivider />
              {filteredDominios.map((item) => (
                <CDropdownItem key={item.dom_id} href="#" onClick={(e) => onSelectDominio(e, item)}>
                  Dominio: {item.dom_id}</CDropdownItem>
              ))
              }
            </CDropdownMenu >
          </CDropdown>
          <CDropdown
            onClick={onDropdownWorkspacesClick}
            id="dropdownWorkspace"
            variant="nav-item"
            direction="center"
            autoClose="outside"
          >
            <CDropdownToggle className="text-white">
              {selectedWorkspace ?
                'WorkSpace: ' + selectedWorkspace
                :
                'Seleccione WorkSpace'}
            </CDropdownToggle>
            <CDropdownMenu className="dropdown-menu-end">
              <CForm className="px-4 py-4">
                <div className="browse_search-bar">
                  <input
                    type="text"
                    value={searchTermWorkspace}
                    onChange={handleSearchWorkspace}
                    placeholder="Buscar WorkSpace"
                  />
                </div>

              </CForm>
              <CDropdownDivider />
              {filteredWorkspaces.map((item) => (
                <CDropdownItem key={item.wks_id} href="#" onClick={(e) => onSelectWorkspace(e, item)}>
                  WorkSpace: {item.wks_id}</CDropdownItem>
              ))}
            </CDropdownMenu >
          </CDropdown>
        </CNavbarNav>
      </CContainer>
    </CNavbar>
  );
};

export default Header;