import 'bootstrap/dist/css/bootstrap.min.css'; // Importa los estilos de Bootstrap
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client'; // Actualiza a React 18 API
import App from './App';
import './index.css'; // Asegúrate de que esto no sobrescriba los estilos de Bootstrap
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si quieres medir el rendimiento en tu app, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint analítico. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();