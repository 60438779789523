import { get_all_records, delete_record, update_record, insert_record, get_dropdown_data } from './connect';
/**
 * Textos del sistema utilizados en varios componentes
 */
export const sys_texts = {
  browse_nuevo: "Nuevo",
  form_guardar: "Guardar",
  form_cerrar: "Cerrar",
  search_placeholder: "Buscar",
  // Otros textos del sistema
};

/**
 * Textos específicos del CRUD para la entidad dominios
 */
export const crud_texts = {
  browse_titulo: "ID de Cruce",
  form_titulo: "ID de Cruce",
  modal_message: "¿Está seguro que desea eliminar este ID?",
  modal_title: "Confirmación",
  modal_positive: "Eliminar",
  modal_negative: "Cancelar",
  // Otros textos específicos del CRUD
};

/**
 * Campos para el componente de navegación (browse)
 */
export const browse_json_fields = [
  { field: 'rule_dom_id', title: 'ID de Dominio', key: true, type: 'text', len: 50 },
  { field: 'rule_cruce_id', title: 'ID de Cruce', key: true, type: 'text', len: 50 },
  { field: 'rule_cruce_name', title: 'Nombre Cruce', type: 'text', len: 255 },
  { field: 'rule_routine_id', title: 'Id de Regla', type: 'text', len: 50 },
  // Otros campos
];

/**
 * Campos para el formulario (form)
 */
export const form_json_fields = [
  { field: 'rule_dom_id', title: 'ID de Dominio', key: true, dom:true, type: 'text', len: 50 },
  { field: 'rule_cruce_id', title: 'ID de Cruce', key: true, type: 'text', len: 50 },
  { field: 'rule_cruce_name', title: 'Nombre Cruce', type: 'text', len: 255 },
  { field: 'rule_cruce_file_nok', title: 'No OK', type: 'text', len: 255 },
  { field: 'rule_cruce_file_max_nok', title: 'Máximo No OK', type: 'number', len: 11 },
  { field: 'rule_routine_id', title: 'Id de Regla', type: 'dropdown', len: 50 },
  // Otros campos específicos para el formulario
];

/**
 * Rutas de navegación
 */
export const routes = {
  edit:'/configuration/cruces/id/edit',
  new: '/configuration/cruces/id/new',
  list: '/configuration/cruces/id'
};

/**
 * Variables del entorno
 */
export const set_vars = () => {
  return {};
};

// Registro seleccionado
let selectedRecord = null;

export const setSelectedRecord = (record) => {
  selectedRecord = record;
};

export const getSelectedRecord = () => {
  return selectedRecord;
};

export { get_all_records, delete_record, update_record, insert_record, get_dropdown_data };