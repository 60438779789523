import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, onHide, onConfirm, message, title, positive, negative }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="primary" onClick={onHide}>
          {negative}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {positive}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
